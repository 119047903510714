
<template>
  <!-- 会员权益体系 -->
  <div>
    <div class="heading">
      <h3>大中型连锁餐饮行业解决方案</h3>
      <p>
        建立私域流量运营矩阵，完成获客、转化、裂变等营销目标，通过360度线索画像以及自动化线索培育,打造企业营销闭环。
      </p>
      <button @click="onapply()">申请试用</button>
    </div>
    <div class="challenge">
      <h5>大中型连锁餐饮行业面临的挑战</h5>
      <div class="challenge_c">
        <div>
          <h4>
            <span>1</span>
            难以直连消费者
          </h4>
          <p>营销终端和供应链条较为复杂，难以实施直连消费者运营策略</p>
        </div>
        <div>
          <h4>
            <span>2</span>
            终端数据割裂
          </h4>
          <p>连锁餐饮店铺之间数据割裂，且难以回流</p>
        </div>
        <div>
          <h4>
            <span>3</span>
            营销活动难做
          </h4>
          <p>
            缺乏全渠道和全链路营销活动的统一管理平台，市场活动往往需要人工操作
          </p>
        </div>
        <div>
          <h4>
            <span>4</span>
            缺乏客户运营
          </h4>
          <p>消费链条过长导致客情维护困难，缺乏持续的购后体验管理和客户运营</p>
        </div>
      </div>
    </div>
    <div class="digitization">
      <h5>微心动助力长租式公寓行业实现数字化转型</h5>
      <p class="title">打造餐饮数字化经营场景，升级门店智慧化营销</p>
      <div class="digitization_c">
        <div class="digitization_t">
          <img src="../../assets/images/number11.png" />
          <div>
            <h6>小程序＋公众号独立线上商城</h6>
            <p>
              实现线上一体化经营，打通连锁餐饮门店之间的数据链，形成综合餐饮连锁管理平台
              <span>在线售卖、发卡发券、外卖经营、配送到家</span>
            </p>
          </div>
        </div>
        <div class="digitization_t">
          <img src="../../assets/images/number08.png" />
          <div>
            <h6>PMS系统</h6>
            <p>
              支持在线包厢预定，同步PMS系统，订单确认率达90%以上
              <span>包厢预定、预定人数、预定时间、券务核销</span>
            </p>
          </div>
        </div>
        <div class="digitization_t">
          <img src="../../assets/images/number09.png" />
          <div>
            <h6>告别佣金平台，扩大门店经营半径</h6>
            <p>
              开启门店自营小程序商城，在线接单，支持多种配送方式
              <span>准时达、配送方式、配送费</span>
            </p>
          </div>
        </div>
        <div class="digitization_t">
          <img src="../../assets/images/number10.png" />
          <div>
            <h6>整套会员管理体系支撑</h6>
            <p>
              打造会员中心，会员储值、会员营销促活拉新，留存转化提高复购率
              <span>会员折扣、积分商城、会员权益、会员等级</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="system">
      <h6>微心动重塑连锁餐饮行业新玩法</h6>
      <p class="title">三大核心能力，行业带来新增长</p>
      <div>
        <p> 全域业绩增长</p>
        <p>有效客户增长</p>
        <p>顾客LTV增长</p>
      </div>
    </div>
        <!--方案 -->
    <div class="programme">
      <h6>解决方案</h6>
      <div>
        <p>
          建立CDP平台，聚合微信、会员、交易等各渠道数据形成全域消费者画像。基于数据洞察之上进行个性化精准触达，提升用户体验，刺激消费转化。
        </p>
        <p>
          全渠道参与会员生命周期管理，自动流程场景工具提升营销效率，丰富营销活动，加强与消费者的互动，增加黏性。
        </p>
        <p>
          针对集团中心，通过DM Hub将集团旗下分店的所有运营打通和数据统一，进行全国门店数据管理。掌握全国门店运营状况并针对性给予支持。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["childEvent"],
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {
    onapply(){
        // this.childEvent(true);
        window.location.href = "http://xdnmxba7z7d6llfo.mikecrm.com/uMx7QGf";
      }
  },
};
</script>

<style scoped lang="less">
.heading {
  height: 6.6rem;
  background-image: url(../../assets/images/industry03.png);
  background-size: 100% 100%;
  text-align: center;
  color: #fff;
  h3 {
    font-size: 0.56rem;
    padding-top: 2.05rem;
  }
  p {
    width: 7.43rem;
    margin: 1em auto 0;
    font-size: 0.2rem;
    line-height: 0.3rem;
    text-align: left;
  }
  button {
    width: 1.48rem;
    height: 0.64rem;
    background-color: #ffc53d;
    border: 0;
    color: #fff;
    font-size: 0.2rem;
    margin-top: 0.4rem;
    border-radius: 0.05rem;
  }
}
div {
  color: #333;
}
.challenge {
  padding: 1.36rem 0 0;
  h5 {
    font-size: 0.28rem;
    text-align: center;
  }
  .challenge_c {
    padding: 0.54rem 2rem;
    display: flex;
    justify-content: space-between;
    div {
      width: 3.6rem;
      text-align: left;
      box-sizing: border-box;
      padding: 0.64rem 0.33rem;
      box-shadow: 10px 10px 30px 1px rgba(0, 0, 115, 0.13);
      h4 {
        font-size: 0.22rem;
        padding-bottom: 0.14rem;
        span {
          display: inline-block;
          width: 0.32rem;
          line-height: 0.32rem;
          background-color: #ffc53d;
          color: #fff;
          border-radius: 0.05rem;
          text-align: center;
          margin-right: 0.1rem;
        }
      }
      p {
        color: #333;
        font-size: 0.18rem;
        line-height: 0.27rem;
      }
    }
  }
}
.digitization {
  background-color: #fffff5;
  padding: 0 1.75rem;
  h5 {
    font-size: 0.28rem;
    padding: 0.7rem 0 0;
    text-align: center;
  }
  .title {
    font-size: 0.18rem;
    text-align: center;
    padding: 0.2rem 0 0.58rem;
  }
  .digitization_c {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .digitization_t {
      width: 7.72rem;
      display: flex;
      box-sizing: border-box;
      padding: 0.36rem 0.52rem 0.36rem 0.36rem;
      background-color: #fff;
      margin-bottom: 0.42rem;
      border-radius: 0.08rem;
      img {
        width: 1rem;
        height: 1rem;
      }
      div {
        padding-left: 0.36rem;
        h6 {
          font-size: 0.22rem;
          padding-bottom: 0.14rem;
        }
        p {
          font-size: 0.18rem;
          line-height: 0.27rem;
          color: #333;
          span {
            padding-top: 0.02rem;
            display: block;
            font-size: 0.14rem;
            color: #666;
          }
        }
      }
    }
  }
}
.system {
  padding: 1rem 3.89rem 1.2rem;
  h6 {
    font-size: 0.28rem;
    text-align: center;
  }
  .title {
    font-size: 0.18rem;
    text-align: center;
    padding: 0.2rem 0 0.62rem;
  }
  div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    p {
      font-size: 0.22rem;
      font-weight: 600;
      line-height: 0.94rem;
      width: 3.3rem;
      height: 0.94rem;
      box-sizing: border-box;
      padding-left: 0.6rem;
      background-image: url(../../assets/images/system_bj.png);
      background-size: 100% 100%;
      box-shadow: 10px 10px 30px 1px rgba(0, 0, 115, 0.13);
      margin: 0 0.4rem 0.45rem 0;
      border-radius: 0.1rem;
      line-height: 0.9rem;
      img {
        width:0.2rem ;
        height: 0.2rem;
        padding-right: 0.1rem;
      }
    }
  }
}
.programme {
  height: 4.34rem;
  background-image: url(../../assets/images/programme_bj.png);
  background-size: 100% 100%;
  margin-bottom: 1rem;
  h6 {
    color: #fff;
    font-size: 0.28rem;
    text-align: center;
    padding-top: 0.68rem;
  }
  div {
    display: flex;
    padding: 1.5rem 0 0 3.46rem;
    p {
      font-size: 0.18rem;
      width: 3.2rem;
      padding-right: 1.34rem;
      line-height: 0.27rem;
      color: #333;
      display: flex;
    }
  }
}
</style>
